import type { LineItem, OrderLineItem, Product } from "@shopware-pwa/types";
import type { GtagItem } from "~/types/gtag";

export const convertLineItemToGtagItem = (lineItem: LineItem | OrderLineItem, quantity = 1): GtagItem => {
	const lineItemPrice = "price" in lineItem ? lineItem.price?.unitPrice || 0 : lineItem?.unitPrice ?? 0;
	return {
		item_id: "productNumber" in lineItem.payload ? lineItem?.payload?.productNumber : "kippie-product",
		item_name: lineItem.label,
		index: 0,
		item_brand: "Kippie",
		price: lineItemPrice,
		quantity: lineItem?.quantity || quantity
	};
};

export const convertProductToGtagItem = (product: Product | KippieProduct, quantity = 1): GtagItem => {
	const itemListId = product.seoCategory?.name?.toLowerCase().replaceAll(" ", "_") || "";

	return {
		item_id: product.productNumber,
		item_name: product.name,
		item_brand: "Kippie",
		item_category: product.seoCategory?.name || "",
		item_list_id: itemListId,
		item_list_name: product.seoCategory?.name || "",
		price: product.calculatedPrice?.totalPrice,
		quantity: quantity
	};
};
