import type { Category, LineItem, Order, OrderLineItem, Product } from "@shopware-pwa/types";
import { convertProductToGtagItem, convertLineItemToGtagItem } from "~/helpers/gtagMapper";

const GTAG_CURRENCY = "EUR";
const onLogin = () => {
	useTrackEvent("login", {
		method: "email"
	});
};

const onRegister = () => {
	useTrackEvent("register", {
		method: "email"
	});
};

const onViewItemList = (category: Category, products: Product[]) => {
	const itemListId = category.name?.toLowerCase().replaceAll(" ", "_");
	useTrackEvent("view_item_list", {
		item_list_id: itemListId,
		item_list_name: category.name,
		items: products?.map((product: Product, index: number) => {
			return {
				...convertProductToGtagItem(product),
				index
			};
		})
	});
};

const onSelectItem = (category: Category, product: KippieProduct) => {
	const itemListId = category.name?.toLowerCase().replaceAll(" ", "_");
	useTrackEvent("select_item", {
		item_list_id: itemListId,
		item_list_name: category.name,
		items: [convertProductToGtagItem(product)]
	});
};

const onViewItem = (product: Product) => {
	useTrackEvent("view_item", {
		currency: GTAG_CURRENCY,
		value: product.calculatedPrice?.totalPrice,
		items: [convertProductToGtagItem(product)]
	});
};

const onAddToCart = (product: Product, quantity: number) => {
	useTrackEvent("add_to_cart", {
		currency: GTAG_CURRENCY,
		value: product.calculatedPrice?.totalPrice * quantity,
		items: [convertProductToGtagItem(product, quantity)]
	});
};

const onRemoveFromCart = (lineItem: LineItem, quantity: number) => {
	useTrackEvent("remove_from_cart", {
		currency: GTAG_CURRENCY,
		value: lineItem?.price?.totalPrice,
		items: [convertLineItemToGtagItem(lineItem, quantity)]
	});
};

const onAddToWishlist = (product: Product) => {
	useTrackEvent("add_to_wishlist", {
		currency: GTAG_CURRENCY,
		value: product.calculatedPrice?.totalPrice,
		items: [convertProductToGtagItem(product)]
	});
};

const onViewCart = () => {
	const { cart, totalPrice } = useCart();

	useTrackEvent("view_cart", {
		currency: GTAG_CURRENCY,
		value: totalPrice,
		items: cart.value?.lineItems?.map((lineItem: LineItem, index: number) => {
			return {
				...convertLineItemToGtagItem(lineItem),
				index
			};
		})
	});
};

const onBeginCheckout = () => {
	const { cart, totalPrice } = useCart();

	useTrackEvent("begin_checkout", {
		currency: GTAG_CURRENCY,
		value: totalPrice,
		items: cart.value?.lineItems?.map((lineItem: LineItem, index: number) => {
			return {
				...convertLineItemToGtagItem(lineItem),
				index
			};
		})
	});
};

const onAddShippingInfo = () => {
	const { cart, totalPrice } = useCart();
	const { selectedShippingMethod } = useCheckout();
	if (!selectedShippingMethod.value) {
		return;
	}

	useTrackEvent("add_shipping_info", {
		currency: GTAG_CURRENCY,
		value: totalPrice,
		shipping_tier: selectedShippingMethod.value?.name,
		items: cart.value?.lineItems?.map((lineItem: LineItem, index: number) => {
			return {
				...convertLineItemToGtagItem(lineItem),
				index
			};
		})
	});
};

const onAddPaymentInfo = () => {
	const { cart, totalPrice } = useCart();
	const { selectedPaymentMethod } = useCheckout();

	if (!selectedPaymentMethod.value) {
		return;
	}

	useTrackEvent("add_payment_info", {
		currency: GTAG_CURRENCY,
		value: totalPrice,
		payment_type: selectedPaymentMethod.value?.name,
		items: cart.value?.lineItems?.map((lineItem: LineItem, index: number) => {
			return {
				...convertLineItemToGtagItem(lineItem),
				index
			};
		})
	});
};

const onPurchase = (order: Order) => {
	useTrackEvent("purchase", {
		transaction_id: order.orderNumber,
		value: order.amountTotal,
		tax: order.amountTotal - order.amountNet,
		shipping: order.shippingTotal,
		currency: GTAG_CURRENCY,
		items: order.lineItems?.map((lineItem: OrderLineItem, index: number) => {
			return {
				...convertLineItemToGtagItem(lineItem, lineItem.quantity),
				index
			};
		})
	});

	useTrackEvent("conversion", {
		send_to: "AW-922717051/40rPCMKV8IcYEPuW_rcD",
		value: order.amountTotal,
		currency: GTAG_CURRENCY,
		transaction_id: order.orderNumber
	});
};

const onPickupLocationSelected = (pickupLocation: any) => {
	useTrackEvent("store_selection", {
		event_category: "ecommerce",
		event_label: pickupLocation.name
	});
};

export const useGtagEvents = () => {
	return {
		onLogin,
		onRegister,
		onViewItemList,
		onSelectItem,
		onViewItem,
		onAddToCart,
		onRemoveFromCart,
		onAddToWishlist,
		onViewCart,
		onBeginCheckout,
		onAddShippingInfo,
		onAddPaymentInfo,
		onPurchase,
		onPickupLocationSelected
	};
};
